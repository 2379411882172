<template>
    <v-dialog v-model="appCharts.open_disponibilidade_resumida" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <div class="status_mchine">
                <v-card class="mx-2">
                    <v-toolbar color="primary">
                        <v-btn icon="mdi-close" @click="appCharts.open_disponibilidade_resumida = false"></v-btn>
                    </v-toolbar>
                    <v-row class="mt-2">
                        <v-col cols="12" md="4" class="pa-1 pl-md-4">
                            <v-card class="text-start d-flex rounded pa-3 mb-4" color="#4CAF50" dark>
                                <v-row justify="space-between" align="center">
                                    <v-col>
                                        <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                            <strong>PRODUÇÃO</strong>
                                        </h4>
                                    </v-col>
                                    <v-col class="text-right">
                                        <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                            <strong>{{ resourceStatusFabrica.trabalhando.length }}</strong>
                                        </h4>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4" class="pa-1 pl-md-2">
                            <v-card class="text-start d-flex rounded pa-3" color="#C62828" dark>
                                <v-row justify="space-between" align="center">
                                    <v-col>
                                        <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                            <strong>PARADA</strong>
                                        </h4>
                                    </v-col>
                                    <v-col class="text-right">
                                        <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                            <strong>{{ resourceStatusFabrica.parada.length }}</strong>
                                        </h4>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4" class="pa-1 py-2 px-2 mt-1 justify-space-between">
                            <v-row>
                                <v-col cols="4" md="4" class="pa-1">
                                    <v-card class="d-flex align-center rounded pa-3" color="#FB8C00" dark>
                                        <v-row justify="space-between" align="center">
                                            <v-col>
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                                    <strong>SETUP</strong>
                                                </h4>
                                            </v-col>
                                            <v-col class="text-right">
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                                    <strong>{{ resourceStatusFabrica.setup.length }}</strong>
                                                </h4>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>

                                <v-col cols="4" md="4" class="pa-1">
                                    <v-card class="d-flex align-center rounded pa-3" color="#2979FF" dark>
                                        <v-row justify="space-between" align="center">
                                            <v-col>
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                                    <small>MANUTENÇÃO</small>
                                                </h4>
                                            </v-col>
                                            <v-col class="text-right">
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em;">
                                                    <strong>{{ resourceStatusFabrica.manutencao.length }}</strong>
                                                </h4>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>

                                <v-col cols="4" md="4" class="pa-1">
                                    <v-card class="d-flex align-center rounded pa-3 mr-md-5" color="#9E9E9E" dark>
                                        <v-row justify="space-between" align="center">
                                            <v-col>
                                                <h4 class="mb-0"
                                                    style="color: #FFFFFF; font-size: clamp(1em, 2vw, 1.5em); height: 25px; white-space: nowrap;">
                                                    <small>DESLIGADA</small>
                                                </h4>
                                            </v-col>
                                            <v-col class="text-right">
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em;">
                                                    <strong>{{ resourceStatusFabrica.offline.length }}</strong>
                                                </h4>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <v-row class="mt-0">

                <v-col cols="12" sm="4" style="height: 100vh;">
                    <v-card class="ml-2" style="width: 101%; height: 100%; background-color: grey; overflow-y: auto;">
                        <v-row justify="start;">
                            <v-col cols="12" v-for="machine in resourceStatusFabrica.trabalhando" :key="machine.name"
                                :status="'producao'">
                                <card_recursos_status :name="machine.name" :motivo_de_parada="machine.motivo_de_parada"
                                    :op="machine.op" :dt_inicio="machine.dt_inicio" :color="'#4CAF50'"
                                    :tempExecucao="tempExecucao" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="4" style="height: 100vh">
                    <v-card style="width: 101%; height: 100%; background-color: grey; overflow-y: auto;">
                        <v-row justify="start;">
                            <v-col cols="12" v-for="machine in resourceStatusFabrica.parada" :key="machine.name"
                                :status="'parada'">
                                <card_recursos_status :name="machine.name" :motivo_de_parada="machine.motivo_de_parada"
                                    :op="machine.op" :dt_inicio="machine.dt_inicio" :color="'#C62828'"
                                    :tempExecucao="tempExecucao" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="4" class="pl-1" style="height: 100vh">
                    <v-card class="mr-2" style="height: 100%; background-color: grey; overflow-y: auto;">
                        <v-row justify="start">
                            <v-col cols="12" v-for="machine in resourceStatusFabrica.offline" :key="machine.name"
                                :status="'offline'">
                                <card_recursos_status :name="machine.name" :motivo_de_parada="machine.motivo_de_parada"
                                    :op="machine.op" :dt_inicio="machine.dt_inicio" :color="'#2B353B'"
                                    :tempExecucao="tempExecucao" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import { useAppCharts } from '../../../stores/appCharts';
import { useAppMaquinas } from '../../../stores/appMaquinas';
import { CalculaTempoMaquina } from '../../utils/funcoes';
import card_recursos_status from './cards/cards_recursos_status.vue';

export default {
    name: "charts_recursos_por_status",
    components: {
        card_recursos_status
    },
    props: { apontamento: Object },
    data() {
        return {
            dialog: true,
            producao: [],
            parada: [],
            setup_manutencao_offline: [],
            timer: null,
            tempExecucao: '00:00:00',
            isMenuOpen: false,
            currentMachine: null,
        }

    },

    computed: {
        appCharts() {
            return useAppCharts();
        },
        resourceMaquinas() {
            return useAppMaquinas().resourceMaquinas;
        },
        resourceStatusFabrica() {
            return useAppCharts().resourceStatusFabrica;
        }

    },
    async mounted() {

    },

    watch: {
        resourceMaquinas: {
            handler(newValue) {
                this.appCharts.status_atual_fabrica();
            },
            deep: true
        },
        apontamento: {
            handler(newVal) {
                if (newVal) {
                    const machine = this.resourceMaquinas.find(recurso => recurso.apontamentos.some(apt => apt.status === 'Aberto'));
                    if (machine && machine.dt_inicio && this.currentMachine !== machine.name) {
                        this.currentMachine = machine.name;
                        this.inicia_count_machine(machine.dt_inicio);
                    }
                }
            },
            deep: true
        }
    },
    methods: {
        inicia_count_machine(dt_inicio) {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                this.tempoApontamento(dt_inicio);
            }, 1000);
        },

        tempoApontamento(dt_inicio) {
            this.tempExecucao = CalculaTempoMaquina(
                new Date(dt_inicio)
            ).tempo
        },
    }
};
</script>

<style scoped>
.pa-1 {
    padding: 3%;
}

.pa-2 {
    padding: 2%;
}

.pa-3 {
    padding: 16%;
}
</style>
